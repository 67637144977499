<template>
  <div id="app">
    <div class="d-flex justify-content-around">
      <div class="bar-code-reader d-flex text-center">
        <BarCodeReader/>
      </div>
      <div class="upload-zone d-flex text-center">
        <UploadZone/>
      </div>
    </div>
  </div>
</template>

<script>
import UploadZone from "@/components/UploadZone";
import BarCodeReader from "@/components/BarCodeReader";

export default {
  name: 'App',
  components: {
    BarCodeReader,
    UploadZone
  }
}
</script>

<style>

.bar-code-reader {
  height: 50vh;
}

.upload-zone {
  height: 50vh;
}
</style>
