<!--https://www.npmjs.com/package/vue-barcode-reader-->
<template>
  <div class="justify-content-around">
    <div class="clickable" @click="startScanner"><b-icon font-scale="5" icon="upc-scan" variant="primary"></b-icon>
      <p>Scanner un code barre</p>
    </div>
    <p>Code barre scanné : <span class="barcode">{{ this.codeBarre }}</span></p>
    <div v-show="scanner">
      <StreamBarcodeReader v-if="scanner" ref="scanner" @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
    </div>
  </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader';


export default {
  components: {
    StreamBarcodeReader
  },
  data() {
    return {
      scanner: false,
      codeBarre: null
    }
  },
  name: "BarCodeReader",
  methods: {
    onDecode: function (output) {
      this.codeBarre = output
      this.scanner = false
    },
    onLoaded: function () {
    },
    startScanner: function () {
      this.scanner = true
    }
  }
};
</script>

<style scoped>
.barcode {
  font-size: 2vh;
  font-weight: bold;
}

.clickable{
  cursor: pointer;
}
</style>
