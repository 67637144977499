<!--https://www.npmjs.com/package/vue-image-upload-resize-->
<template>
  <div>
  <image-uploader
      :debug="1"
      :maxWidth="1024"
      :quality="0.9"
      :autoRotate=true
      outputFormat="verbose"
      :preview=true
      :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
      :capture=false
      accept="video/*,image/*"
      doNotResize="['gif', 'svg']"
      @input="setImage"
  >
    <label class="clickable" for="fileInput" slot="upload-label">
      <b-icon font-scale="5" icon="camera" variant="primary"></b-icon>
      <p>Prendre ou télécharger une photo</p>
    </label>
  </image-uploader>
    <div class="base64">
      <p>Informations image : {{ hasImage ? this.image.info : null }}</p>
    </div>
<p>fsdfsdfsdfsdfsdfsd</p>
<input type="file">
  </div>
</template>

<script>
import ImageUploader from 'vue-image-upload-resize'

export default {
  components: {
    ImageUploader
  },
  name: "UploadZone",
  data() {
    return {
      hasImage: false,
      image: null
    };
  },
  methods: {
    setImage: function(output) {
      this.hasImage = true;
      this.image = output;
      console.log(output.dataUrl)
      console.log('Info', output.info)
      console.log('Exif', output.exif)
    }
  }
};
</script>

<style>
.base64 {
  word-wrap:break-word;
}
#fileInput {
  display: none;
}

.clickable {
  cursor: pointer;
}
</style>
